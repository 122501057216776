<template>
  <div v-if="practice">
    <Breadcrumb>
      <template slot="restPage">
        <el-breadcrumb-item>
          <router-link :to="{ name: 'SatManagement' }">
            View
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="composePractice">
          <router-link
            :to="{
              name: 'SatComposePracticeDetail',
              query: { id: composePractice.id }
            }"
          >
            {{ composePractice.exam.title }}
          </router-link>
        </el-breadcrumb-item>
        <el-breadcrumb-item v-if="practice">
          {{ `${practice.exam.title}` }}
          （<b v-if="practice.exam.is_public === 1">Published</b>
          <b v-else>Drafts</b>）
        </el-breadcrumb-item>
      </template>
    </Breadcrumb>
    <div class="text-right">
      <div class="title-box-button">
        <template v-if="isEdit">
          <template v-if="this.id && Number(this.id) > 0">
            <el-tooltip
              class="item ml-2"
              effect="dark"
              content="Cancel"
              placement="top"
            >
              <router-link
                :to="{
                  name: $route.name,
                  params: {
                    ...$route.params
                  },
                  query: {
                    ...$route.query,
                    isEdit: 0
                  }
                }"
              >
                <el-button type="danger" size="small" plain>
                  Cancel
                  <i class="fas fa-undo-alt"></i>
                </el-button>
              </router-link>
            </el-tooltip>
          </template>
        </template>
        <template v-else>
          <el-tooltip
            class="item ml-2"
            effect="dark"
            content="Edit"
            placement="top"
          >
            <router-link
              :to="{
                name: $route.name,
                params: {
                  ...$route.params
                },
                query: {
                  ...$route.query,
                  isEdit: 1
                }
              }"
            >
              <el-button type="success" size="small">
                Edit
                <i class="fa fa-edit"></i>
              </el-button>
            </router-link>
          </el-tooltip>
        </template>
        <template v-if="!composePractice">
          <el-tooltip
            v-show="practice.exam.is_public === 1"
            class="ml-2"
            effect="dark"
            content="Change to draft"
            placement="top"
          >
            <el-button
              type="danger"
              size="small"
              @click="updateInfo({ is_public: 0 })"
            >
              Change to draft
              <i class="fas fa-level-down-alt"></i>
            </el-button>
          </el-tooltip>
          <el-tooltip
            v-show="practice.exam.is_public === 0"
            class="ml-2"
            effect="dark"
            content="Publish"
            placement="top"
          >
            <el-button
              type="success"
              size="small"
              @click="updateInfo({ is_public: 1 })"
            >
              Publish
              <i class="fas fa-upload"></i>
            </el-button>
          </el-tooltip>
        </template>
      </div>
    </div>
    <hr />
    <div ref="ivy-question">
      <el-form ref="form" :model="practice" label-width="180px">
        <el-form-item label="Name">
          <el-input
            v-show="isEdit"
            v-model="practice.exam.title"
            maxlength="100"
            show-word-limit
            @change="updateInfo({ title: practice.exam.title })"
          ></el-input>
          <b v-show="!isEdit">
            {{ practice.exam.title }}
          </b>
        </el-form-item>
        <!-- <el-form-item label="Type" v-if="isAdmin">
          <el-radio-group
            v-show="isEdit"
            v-model="isDrill"
            :disabled="!isEdit"
            @change="updateDrill(isDrill)"
          >
            <el-radio-button label="default">
              {{ $t(`sat.Partial Tests`) }}
            </el-radio-button>
            <el-radio-button label="drill">
              {{ $t(`sat.Drill Practice`) }}
            </el-radio-button>
          </el-radio-group>
          <div v-show="!isEdit">
            <b v-if="isDrill === 'default'"> {{ $t(`sat.Partial Tests`) }}</b>
            <b v-if="isDrill === 'drill'"> {{ $t(`sat.Drill Practice`) }}</b>
          </div>
        </el-form-item> -->
        <!-- <el-form-item label="Subjects" v-show="isEdit">
          <el-radio-group
            v-model="practice.subject_id"
            :disabled="!isEdit"
            @change="updateSubject(practice.subject_id)"
          >
            <el-radio-button
              v-for="subject in subjectsNow"
              :key="subject.id"
              :label="subject.id"
            >
              {{ titleCase(subject.name) }}
            </el-radio-button>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item>
          <div slot="label">
            Question(s)
          </div>
          <div>
            <el-alert type="success" :closable="false" class="mb-3">
              <h6 class="m-2">
                Questions:
                {{ practice.exam.exam_questions.length }}
              </h6>
            </el-alert>
          </div>
          <div class="mb-4" v-if="isEdit && !hasTaken">
            <el-button
              type="success"
              style="width: 100%;"
              @click="showCreateNewQuestionDialog = true"
            >
              Add <i class="fa fa-plus"></i>
            </el-button>
          </div>
          <el-alert
            v-if="hasTaken && isEdit"
            class="mb-4 p-3"
            title="This Test has been tested by students. Only basic information can be edited, and the number and order of units cannot be changed."
            type="warning"
            show-icon
            :closable="false"
          >
          </el-alert>
          <el-alert
            v-if="!hasTaken && isEdit"
            class="mb-4 p-3"
            title="Drag and drop questions to sort them."
            type="info"
            show-icon
            :closable="false"
          >
          </el-alert>

          <div v-if="practice.exam.exam_questions.length > 0 && isEdit">
            <el-collapse accordion>
              <draggable
                :list="practice.exam.exam_questions"
                :class="!isEdit || hasTaken ? '' : 'question-card'"
                :disabled="!isEdit || hasTaken"
                @change="setQuestionOrder"
              >
                <el-collapse-item
                  v-for="(question, index) in practice.exam.exam_questions"
                  :key="question.id"
                >
                  <template slot="title">
                    <div class="show-question">
                      <div class="show-question-kbd">
                        <kbd>{{ index + 1 }}</kbd>
                      </div>
                      <div class="show-question-passage">
                        <h6
                          v-if="
                            question.question.sat_passage &&
                              question.question.sat_passage.content
                          "
                          class="ml-2 show-passage"
                          v-html="question.question.sat_passage.content"
                        ></h6>
                        <h6
                          class="m-0 ml-2 quick-view-question"
                          v-html="question.question.content"
                        ></h6>
                      </div>
                      <div class="buttons text-right" v-show="isEdit">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Preview"
                          placement="top"
                        >
                          <router-link
                            :to="{
                              name: 'QuestionPreview',
                              params: {
                                questionId: question.question_id
                              }
                            }"
                          >
                            <el-button type="success" size="mini">
                              <i class="fas fa-eye"></i>
                            </el-button>
                          </router-link>
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Edit"
                          placement="top"
                          v-if="isAdmin"
                        >
                          <router-link
                            :to="{
                              name: 'SatEditQuestion',
                              query: {
                                questionId: question.question.id
                              }
                            }"
                          >
                            <el-button type="warning" size="mini">
                              <i class="fas fa-edit"></i>
                            </el-button>
                          </router-link>
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="Remove"
                          placement="top"
                          v-show="isEdit && !hasTaken"
                        >
                          <el-button
                            type="danger"
                            size="mini"
                            @click.stop="removeQuestion(question.id)"
                          >
                            <i class="fas fa-times-circle"></i>
                          </el-button>
                        </el-tooltip>
                      </div>
                    </div>
                  </template>
                  <div class="options-box mb-2">
                    <ul
                      class="ivy-question-options"
                      v-if="question.question.type === 'default'"
                    >
                      <li
                        class="option"
                        v-for="(option, index) in question.question.sat_options"
                        :key="index"
                      >
                        <div style="width:1.5rem">
                          <span class="text-success">
                            <i
                              v-if="
                                isAnswer(
                                  question.question.sat_answers,
                                  option.letter
                                )
                              "
                              class="fas fa-check-circle"
                            ></i>
                          </span>
                        </div>
                        <span style="width:1.3rem">{{
                          `${option.letter}) `
                        }}</span>
                        <div class="optionText" v-html="option.title"></div>
                      </li>
                    </ul>
                    <template v-else>
                      <h6>
                        <b>Answer:</b>
                        <span
                          class="ml-1"
                          v-for="(answer, index) in question.question
                            .sat_answers"
                          :key="index"
                        >
                          {{ answer.answers[0] }}
                          <span
                            v-if="
                              index < question.question.sat_answers.length - 1
                            "
                          >
                            ,
                          </span>
                        </span>
                      </h6>
                    </template>
                    <div
                      v-if="
                        (question.question.sat_question_tags || []).length > 0
                      "
                      class="mb-2 mt-2"
                    >
                      <b>
                        Topics:
                        <el-tag
                          v-for="tag in question.question.sat_question_tags"
                          :key="tag.id"
                          type="success"
                          size="small"
                          class="ivy-tag"
                        >
                          {{ `[${tag.type}] - ${tag.name}` }}
                        </el-tag>
                      </b>
                    </div>
                    <div
                      v-if="
                        question.question.explanation &&
                          question.question.explanation !== ''
                      "
                      class="mb-2 mt-2"
                    >
                      <b class="mr-2">Explanation:</b>
                      <span v-html="question.question.explanation"></span>
                    </div>
                  </div>
                </el-collapse-item>
              </draggable>
            </el-collapse>
          </div>
          <div v-else>
            <QuestionsTable
              :showQuestion="true"
              :showPassage="true"
              :showOptions="true"
              :showAnswer="true"
              :showExplanation="false"
              :showLabels="false"
              :questions="showQuestions"
              :search="$route.query.search"
              :isAdmin="isAdmin"
            >
            </QuestionsTable>
          </div>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      @close="reload"
      class="add-passage-dialog"
      :visible.sync="showCreateNewQuestionDialog"
      :close-on-click-modal="false"
      width="90%"
      top="2vh"
    >
      <div slot="title">
        Add question: <kbd>{{ practice.exam.exam_questions.length + 1 }}</kbd>
      </div>
      <el-form ref="form" label-width="180px" v-if="isAdmin">
        <el-form-item :label="$t('AddSubject.Method')">
          <el-radio-group v-model="addMode">
            <el-radio-button :label="1">
              Choose from Existing
            </el-radio-button>
            <el-radio-button :label="0">
              Create a New Question
            </el-radio-button>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="content-box" v-if="addMode === 0">
        <SmartInput
          :isNew="isNew"
          :sat_subject_id="this.practice.subject_id"
          :sat_id="0"
          :practiceId="id"
          @addNewQuestionToPractice="addNewQuestionToPractice"
        ></SmartInput>
      </div>
      <template v-if="addMode === 1">
        <AllQuestions
          :practiceQuestions="practice.exam.exam_questions"
          @addQuestion="addQuestion"
          :isAdmin="isAdmin"
          :isTeacher="isTeacher"
          :isCompany="isCompany"
        ></AllQuestions>
      </template>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import Breadcrumb from "@/components/Breadcrumb";
import SAT from "@/apis/sat.js";
import Common from "@/mixins/common.js";
import AllQuestions from "@/views/satPractice/setQuestions.vue";
import SmartInput from "@/views/satAddQuestion/components/SmartInput";
import draggable from "vuedraggable";
import QuestionsTable from "@/views/allQuestions/QuestionsTable.vue";

export default {
  metaInfo() {
    return {
      title: `${this.PageTitle} - ${this.CompanyName}`
    };
  },

  components: {
    Breadcrumb,
    draggable,
    AllQuestions,
    SmartInput,
    QuestionsTable
  },

  mixins: [Common],

  props: [],
  data() {
    return {
      isDrill: "default",
      addMode: 1,
      showCreateNewQuestionDialog: false,
      practice: {
        exam: {
          title: null,
          exam_questions: []
        },
        subject_type: null,
        subject_id: null
      },
      composePractice: null,
      practices: [],
      subjects: [],
      question: {
        sat_passage_id: null,
        type: "default",
        content: "",
        explanation: "",
        sat_question_tag_ids: [],
        sat_options: [
          {
            order: 1,
            letter: "A",
            title: ""
          },
          {
            order: 2,
            letter: "B",
            title: ""
          },
          {
            order: 3,
            letter: "C",
            title: ""
          },
          {
            order: 4,
            letter: "D",
            title: ""
          }
        ],
        sat_answers: [
          {
            answers: []
          }
        ]
      },
      passage: {
        introduction: [],
        content: "",
        below_content: ""
      }
    };
  },
  computed: {
    showQuestions() {
      let questions = [];
      if (
        this.practice.exam.exam_questions &&
        this.practice.exam.exam_questions.length > 0
      ) {
        this.practice.exam.exam_questions.forEach(question => {
          questions.push(question.question);
        });
      }
      return questions;
    },
    hasTaken() {
      return (
        this.practice.exam.user_exams_count &&
        this.practice.exam.user_exams_count > 0
      );
    },
    isNew() {
      if (
        (this.practice && this.practice.exam.version === 2) ||
        this.isTeacher ||
        this.isCompany
      ) {
        return 1;
      } else {
        return 0;
      }
    },
    PageTitle() {
      if (this.practice) {
        return this.practice.exam.title;
      } else {
        return "Loading...";
      }
    },
    id() {
      return Number(this.$route.query.id);
    },
    isTeacher() {
      return this.$route.name === "TeacherSatPracticeDetail";
    },
    isCompany() {
      return this.$route.name === "CompanySatPracticeDetail";
    },
    isAdmin() {
      return this.$route.name === "SatPracticeDetail";
    },
    isEdit() {
      let isEdit = 0;
      if (this.$route.query.isEdit) {
        isEdit = Number(this.$route.query.isEdit);
      }
      return isEdit;
    },
    subjectsNow() {
      let subjects = [];
      // if (this.isNew === 1) {
      //   subjects = [this.subjects[4], this.subjects[2]];
      // } else {
      //   subjects = [
      //     this.subjects[0],
      //     this.subjects[1],
      //     this.subjects[2],
      //     this.subjects[3]
      //   ];
      // }
      subjects = this.subjects;
      return subjects;
    }
  },
  watch: {},

  async mounted() {
    const subjects = await SAT.getSubjects();
    this.subjects = subjects.sat_subjects;
    if (
      this.$route.params.composePracticeId &&
      this.$route.params.composePracticeId > 0
    ) {
      await this.getComposePracticeDetail(this.$route.params.composePracticeId);
    }
    this.getPractices();
    if (this.id && Number(this.id) > 0) {
      this.getPracticeDetail(0);
    } else {
      this.isEdit = true;
    }
    if (this.$route.query.subject_id) {
      this.practice.subject_id = this.$router.query.subject_id;
    }

  },

  methods: {
    reload() {
      if (this.id && Number(this.id) > 0) {
        this.getPracticeDetail();
      }
    },
    isAnswer(answers, answer) {
      let isAnswer = false;
      answers.forEach(e => {
        if (e.answers && e.answers.length > 0) {
          e.answers.forEach(item => {
            if (item) {
              if (item.indexOf(answer) > -1) {
                isAnswer = true;
              }
            }
          });
        }
      });
      return isAnswer;
    },
    initMathJaxPlugin() {
      this.$mathJax.loadMathJaxScript(() => {
        this.$mathJax.initMathJaxConfig();
        this.$mathJax.MathQueue([this.$refs["ivy-question"]]);
      });
    },
    async setQuestionOrder() {
      let arr = [];
      this.practice.exam.exam_questions.forEach((item, index) => {
        arr.push({
          id: item.id
        });
      });
      await SAT.updatePracticeQuestionsOrder(this.id, {
        exam_questions: arr
      });
      await this.getPracticeDetail();
    },
    async addNewQuestionToPractice(type) {
      // const id = await this.saveAll();
      // await this.addQuestionWithId(id);
      // this.question = {
      //   sat_passage_id: null,
      //   type: "default",
      //   content: "",
      //   explanation: "",
      //   sat_question_tag_ids: [],
      //   sat_options: [
      //     {
      //       order: 1,
      //       letter: "A",
      //       title: ""
      //     },
      //     {
      //       order: 2,
      //       letter: "B",
      //       title: ""
      //     },
      //     {
      //       order: 3,
      //       letter: "C",
      //       title: ""
      //     },
      //     {
      //       order: 4,
      //       letter: "D",
      //       title: ""
      //     }
      //   ],
      //   sat_answers: [
      //     {
      //       answers: []
      //     }
      //   ]
      // };
      // this.passage = {
      //   introduction: [],
      //   content: "",
      //   below_content: ""
      // };
      this.showCreateNewQuestionDialog = false;
      if (type === "addNewQuestion") {
        let box = document.getElementsByClassName("content-box")[0];
        box.scrollTo(0, 0);
        setTimeout(() => {
          this.showCreateNewQuestionDialog = true;
        }, 500);
      }
    },
    async updateInfo(data) {
      await SAT.updatePractice(this.id, {
        practice: {
          exam: {
            title: this.practice.exam.title,
            ...data
          }
        }
      });
      await this.getPracticeDetail();
    },
    async updateSubject(subject_id) {
      await SAT.updatePractice(this.id, {
        practice: {
          exam: {
            title: this.practice.exam.title
          },
          subject_id: subject_id,
          subject_type: "Modules\\SAT\\Entities\\SatSubject"
        }
      });
      await this.getPracticeDetail();
    },
    async updateDrill(type) {
      await SAT.updatePractice(this.id, {
        practice: {
          exam: {
            title: this.practice.exam.title
          },
          type: type
        }
      });
      await this.getPracticeDetail();
    },
    async removeQuestion(questionId) {
      this.$confirm(
        "Do you wish to remove this question from this practice test?",
        "",
        {
          confirmButtonText: this.$t("alert.confirm"),
          cancelButtonText: this.$t("alert.cancel"),
          type: "warning"
        }
      )
        .then(async () => {
          await SAT.removePracticeQuestion(this.id, {
            exam_question_id: questionId
          });
          await this.getPracticeDetail();
          this.$message({
            type: "success",
            message: "Success!"
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: this.$t("alert.Canceled")
          });
        });
    },
    addQuestion(newQuestion) {
      this.addQuestionWithId(newQuestion.id);
    },
    async addQuestionWithId(id) {
      await SAT.addPracticeQuestion(this.id, {
        question_id: id,
        question_type: "Modules\\SAT\\Entities\\SatQuestion"
      });
      await this.getPracticeDetail();
    },
    async getComposePracticeDetail(id) {
      const res = await SAT.getComposePracticeDetail(id);
      this.composePractice = {
        ...res.compose_practice,
        compose_practice_practices: res.compose_practice.practices
      };
      
    },
    async getPractices() {
      const res = await SAT.getPractices();
      this.practices = res.practices;
    },
    async getPracticeDetail(type = 1) {
      if (this.isEdit && type === 1) {
        this.$notify({
          title: "Success!",
          type: "success"
        });
      }
      const res = await SAT.getEditorPracticeDetail(this.id);
      // const res = await SAT.getPracticeDetail(this.id);
      this.practice = res.practice;
      this.isDrill =
        this.practice && this.practice.exam.category === 1
          ? "drill"
          : "default";

      if (!this.practice.subject_id && (this.isTeacher || this.isCompany)) {
        this.practice.subject_id = 5;
      }
      this.initMathJaxPlugin();
    },
    showSubject(id) {
      let subjectName = "";
      this.subjects.forEach(subject => {
        if (subject.id == id) {
          subjectName = this.titleCase(subject.name);
        }
      });
      return subjectName;
    },
    setQuestion(value) {
      this.question = { ...this.question, ...value };
    },
    setPassage(value) {
      this.passage = { ...this.passage, ...value };
    },
    selectPassage(passage) {
      this.question.sat_passage_id = passage.id;
      this.passage = passage;
      this.showCreateNewPassagesDialog = false;
    },
    async saveAll() {
      await this.saveNewPassage();
      const id = await this.saveQuestion();
      return id;
    },
    async saveNewPassage() {
      if (this.passage.content && this.passage.content !== "") {
        if (this.question.sat_passage_id && this.question.sat_passage_id > 0) {
          await SAT.updatePassage(this.question.sat_passage_id, {
            ...this.passage,
            title: this.passage.title ? this.passage.title : "",
            introduction: this.passage.introduction
              ? this.passage.introduction
              : []
          });
        } else {
          const res = await SAT.addPassage({
            ...this.passage,
            sat_id: 0,
            is_new: this.isNew,
            sat_subject_id: this.practice.subject_id
          });
          this.question.sat_passage_id = res.sat_passage.id;
        }
      }
    },
    async saveQuestion() {
      const res = await SAT.addNewQuestion({
        ...this.question,
        sat_id: 0,
        is_new: this.isNew,
        sat_subject_id: this.practice.subject_id
      });
      return res.sat_question.id;
    }
  }
};
</script>

<style scoped>
@font-face {
  font-family: DroidSerif;
  src: url("https://ivy-way.s3.ap-northeast-1.amazonaws.com/fonts/DroidSerif.ttf");
}
.title-box {
  display: flex;
  align-items: center;
}
.title-box .title-box-input {
  width: 50%;
}
/* .title-box .title-box-button {
  width: 50px;
} */
::v-deep .el-form-item__content {
  /* line-height: 1.5; */
}
.question-card {
  cursor: move;
}

::v-deep .el-dialog__body {
  padding: 10px 20px;
}
::v-deep .el-collapse-item__content {
  padding: 0 1rem;
}
::v-deep .el-collapse-item__header {
  padding: 0.5rem 0 0.5rem 1rem;
  height: auto;
  min-height: 48px;
  line-height: normal;
  align-items: start;
}
.show-question {
  font-family: DroidSerif;
  display: flex;
  flex: 1;
  width: 100%;
  overflow: hidden;
  justify-content: center;
}
.options-box {
  font-family: DroidSerif;
}
.show-question-kbd {
  width: 2rem;
}
.show-question-kbd {
  padding-top: 0.1rem;
  width: 2rem;
}
.show-question-passage {
  flex: 1;
  overflow: hidden;
}

.show-passage {
  width: 100%;
  height: 20px;
  word-break: keep-all;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.is-active .show-passage {
  height: auto;
  word-break: normal;
  white-space: normal;
  overflow: auto;
  text-overflow: unset;
}
.quick-view-question {
  font-weight: 700;
}
::v-deep .quick-view-question img,
::v-deep .show-passage img {
  display: none;
}
::v-deep .is-active .quick-view-question img,
::v-deep .is-active .show-passage img {
  /* width: 50%; */
  display: inline-block;
}

::v-deep .el-collapse-item__header .el-icon-arrow-right {
  margin-top: 0.5rem;
  margin-left: 1rem;
  width: 15px;
}
/* ::v-deep .el-collapse-item__header p {
  margin: 0;
} */
.content-box {
  /* background: #fafafa; */
  padding: 1rem 0;
  height: 700px;
  width: 100%;
  overflow: auto;
}
.options-box {
  padding-left: 2rem;
}
.ivy-question-options {
  font-family: DroidSerif;
  font-size: 16px;
  padding-left: 0px;
  margin-bottom: 0px;
  list-style: none;
}
.ivy-question-options .option {
  display: flex;
  font-size: 16px;
  line-height: 20px;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
  padding: 0;
}
.ivy-question-options .optionText {
  flex: 1;
}
::v-deep .ivy-question-options .option p {
  margin-bottom: 0 !important;
}
.buttons {
  max-width: 185px;
}
.buttons .item {
  margin-left: 1rem;
}
.ivy-tag {
  margin: 0 10px 10px 10px;
}
::v-deep .MathJax .mrow {
  font-size: 1.1rem !important;
}
::v-deep .question-card .el-collapse-item__header:hover {
  cursor: move;
  opacity: 0.6;
}
</style>
